/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Grid,
  TextField,
  Slider,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const InputComponent = ({ elements, setOutput }) => {
  if (!elements) {
    return <div />;
  }

  const [values, setValues] = useState(
    elements.reduce((acc, element) => ({ ...acc, [element.id]: element.defaultValue }), {})
  );

  const handleChange = (event, elementId) => {
    let newValue;
    if (event.target.type === "checkbox") {
      newValue = event.target.checked;
    } else {
      newValue = event.target.value;
    }

    const updatedValues = { ...values, [elementId]: newValue };
    setValues(updatedValues);
    setOutput(updatedValues);
  };

  const shouldFillScreen = elements.length === 1 && elements[0].type === "TextField";

  return (
    <div style={{ height: shouldFillScreen ? "100vh" : "auto" }}>
      <Grid container spacing={2}>
        {elements.map((element) => (
          <Grid
            item
            xs={12}
            sm={element.type === "TextField" ? 12 : 4}
            md={element.type === "TextField" ? 12 : 4}
            key={element.label}
          >
            {(() => {
              switch (element.type) {
                case "TextField":
                  return (
                    <TextField
                      fullWidth
                      multiline
                      label={element.label}
                      minRows={6}
                      value={values[element.id]}
                      onChange={(e) => handleChange(e, element.id)}
                      InputLabelProps={{ shrink: true }}
                      variant="standard"
                      style={{ height: shouldFillScreen ? "100%" : "auto", width: "100%" }}
                    />
                  );
                case "TextInput":
                  return (
                    <TextField
                      fullWidth
                      label={element.label}
                      value={values[element.id]}
                      onChange={(e) => handleChange(e, element.id)}
                    />
                  );
                case "Slider":
                  return (
                    <div>
                      <InputLabel>{element.label}</InputLabel>
                      <Slider
                        defaultValue={element.defaultValue}
                        min={element.min}
                        max={element.max}
                        step={element.step}
                        onChange={(e, value) => handleChange({ target: { value } }, element.id)}
                      />
                    </div>
                  );
                case "Switch":
                  return (
                    <FormControlLabel
                      control={<Switch onChange={(e) => handleChange(e, element.id)} />}
                      label={element.label}
                    />
                  );
                case "Dropdown":
                  return (
                    <FormControl fullWidth>
                      <InputLabel>{element.label}</InputLabel>
                      <Select
                        value={values[element.id]}
                        onChange={(e) => handleChange(e, element.id)}
                      >
                        {element.options.map((option) => (
                          <MenuItem key={option.label} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                default:
                  return null;
              }
            })()}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default InputComponent;
