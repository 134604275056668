const firebaseConfig = {
  apiKey: "AIzaSyD3ffbf3tWaWLC0Jnjc3aSoJhoePlVFxc8",
  authDomain: "nika-178db.firebaseapp.com",
  projectId: "nika-178db",
  storageBucket: "nika-178db.appspot.com",
  messagingSenderId: "888442548907",
  appId: "1:888442548907:web:d840170c71a85ad90ffdee",
  measurementId: "G-KYL0K0RWJ9",
};

export default firebaseConfig;
