import React from "react";
import { Backdrop, InputLabel, Select, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import MKButton from "components/MKButton";
import CustomFormComponent from "./InputComponent";
import getSheetDataByColumn from "../Repository";
import OutputComponent from "./OutputComponent";
import fetchChatGptResponse from "./ChatGPTConnector";
import fetchDalLeResponse from "./DallEConnector";

const DynamicGPTInterface = () => {
  const [prompts, setPrompts] = React.useState([]);
  const [prompt, setPrompt] = React.useState({
    prompt: undefined,
    id: undefined,
    input: undefined,
    output: undefined,
  });

  React.useEffect(() => {
    // call the function
    getSheetDataByColumn().then((e) => {
      setPrompts(e.map((it) => ({ value: it, label: it.id })));
      console.log(e);
    });
  }, []);

  console.log(prompts);

  let elements = [];
  try {
    elements = JSON.parse(prompt.input || "[]") || [];
  } catch (e) {
    console.log(e);
  }

  let outputType = {};
  try {
    outputType = JSON.parse(prompt.output || "{}") || {};
  } catch (e) {
    console.log(e);
  }

  // eslint-disable-next-line react/destructuring-assignment
  const options = prompts.map((e) => <MenuItem value={e.value}>{e.label}</MenuItem>);

  const [settings, setSettings] = React.useState({});

  const [outputPrompt, setOutputPrompt] = React.useState("");

  const [isLoading, setLoading] = React.useState(false);

  const outputValues = [
    {
      value: outputPrompt,
      type: prompt.output,
    },
  ];

  console.log(settings);

  const [language, setLanguage] = React.useState("english"); // Add language state

  const loadingOverlay = (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const handleButtonPress = () => {
    setLoading(true);
    let formattedPrompt = prompt.prompt;
    Object.keys(settings).forEach((key) => {
      formattedPrompt = formattedPrompt.replace(`{${key}}`, settings[key]);
    });
    if (language === "german") {
      formattedPrompt += ". Bitte antworte auf Deutsch.";
    } else {
      formattedPrompt += ". Please answer in English.";
    }

    if (outputType.type === "Image") {
      fetchDalLeResponse(formattedPrompt, (result) => {
        setOutputPrompt(result);
        setLoading(false);
      });
    } else {
      fetchChatGptResponse(formattedPrompt, (result) => {
        setOutputPrompt(result);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <div style={{ minWidth: "650px", width: "100%", position: "center" }}>
        {" "}
        {/* Add this container */}
        <InputLabel id="demo-simple-select-label" style={{ padding: "10px 0px 0px 0px" }}>
          Prompt
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={prompt}
          label="Age"
          defaultValue=""
          onChange={(e) => setPrompt(e.target.value)}
          displayEmpty
          style={{ width: "100%", height: "50px", minWidth: "450px", padding: "10px 0px 0px 0px" }} // Update the style
        >
          <MenuItem value="" disabled>
            Choose ...
          </MenuItem>
          {options}
        </Select>
        <div style={{ marginLeft: "10px" }}>
          <InputLabel id="language-select-label" style={{ padding: "10px 0px 0px 0px" }}>
            Language
          </InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={language}
            label="Language"
            onChange={(e) => setLanguage(e.target.value)}
            style={{
              width: "100%",
              height: "50px",
              minWidth: "100px",
              padding: "10px 0px 0px 0px",
            }}
          >
            <MenuItem value="english">English</MenuItem>
            <MenuItem value="german">German</MenuItem>
          </Select>
        </div>
      </div>
      <br />
      {elements.length ? (
        <CustomFormComponent elements={elements} setOutput={setSettings} />
      ) : (
        <p>No input Available!</p>
      )}
      <br />
      <MKButton
        color="black"
        onClick={() => handleButtonPress()}
        sx={{
          borderRadius: "10rem",
          width: "12.5rem",
          height: "3rem",
          fontSize: "1rem",
        }}
      >
        Submit
      </MKButton>
      <br />
      <OutputComponent elements={outputValues} />
      {isLoading && loadingOverlay}
    </>
  );
};

export default DynamicGPTInterface;
