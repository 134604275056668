import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserSessionPersistence,
} from "firebase/auth";
import config from "components/firebaseconifg";
import { bool } from "prop-types";

const app = initializeApp(config);
const auth = getAuth(app);

export async function login(email, password) {
  await setPersistence(auth, browserSessionPersistence).then(async () => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // eslint-disable-next-line no-console
        console.log("logged in");
        return Promise < bool > true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        return Promise < bool > false;
      });
  });
}

export default login;
