/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// GPTPage page sections
import Footer from "pages/LandingPages/GPTPage/sections/Footer";
// Routes
// import routes from "routes";

// Images
// import bgImage from "assets/images/city-profile.jpg";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import React from "react";

// import { SpaceBar } from "@mui/icons-material";
// import { Grid } from "@mui/material";

import { FormControl } from "@mui/material";
// eslint-disable-next-line import/no-named-as-default
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import config from "../../../components/firebaseconifg";
// eslint-disable-next-line import/no-named-as-default,import/no-cycle
import DynamicGPTInterface from "../../../components/DynamicGPTInterface";

const app = initializeApp(config);
const auth = getAuth(app);

function GPTPage() {
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  });
  const dynamicGPTInterface = <DynamicGPTInterface />;

  const component = (
    <>
      <DefaultNavbar routes={[]} transparent light />
      <MKBox bgColor="#f0f0f0">
        <MKBox
          bgColor="#1e1e1e"
          minHeight="5.5rem"
          width="100%"
          sx={{
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3%",
            marginLeft: "15%",
            marginRight: "15%",
          }}
        >
          {dynamicGPTInterface}
          <FormControl style={{ width: "30%" }} />
        </div>
        <Footer />
      </MKBox>
    </>
  );

  return isSignedIn ? component : <div>You do not have access for this!</div>;
}

export default GPTPage;
