const DEFAULT_PARAMS = {
  model: "gpt-4",
  max_tokens: 2000,
  messages: [
    {
      role: "user",
      content: "Tell the world about the ChatGPT API in the style of a pirate.",
    },
  ],
};

async function chatGptApiCall(promptInput) {
  const filteredInput = promptInput.replace(/(\r\n|\n|\r)/gm, " ");
  const key = "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";

  const params = {
    ...DEFAULT_PARAMS,
    messages: [{ role: "user", content: filteredInput }],
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(String(key)),
    },
    body: JSON.stringify(params),
  };

  const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
  return response.json();
}

async function fetchChatGptResponse(promptInput, setOutput) {
  try {
    const data = await chatGptApiCall(promptInput);
    const result = data.choices[0].message.content;
    setOutput(result);
  } catch (error) {
    console.log(error);
  }
}

export default fetchChatGptResponse;
