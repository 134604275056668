const DEFAULT_PARAMS = {
  model: "image-alpha-001",
  prompt: "",
  num_images: 1,
  size: "256x256",
  response_format: "url",
};

async function dalLeApiCall(promptInput) {
  const key = "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";
  const params = {
    ...DEFAULT_PARAMS,
    prompt: promptInput,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer ".concat(String(key)),
    },
    body: JSON.stringify(params),
  };

  const response = await fetch("https://api.openai.com/v1/images/generations", requestOptions);
  return response.json();
}

async function fetchDalLeResponse(promptInput, setOutput) {
  try {
    const data = await dalLeApiCall(promptInput);
    const result = data.data[0].url;
    setOutput(result);
  } catch (error) {
    console.log(error);
  }
}

export default fetchDalLeResponse;
