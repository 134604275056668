// https://docs.google.com/spreadsheets/d/1v0Rq9FDatEwC8yKdqNWFtmIgLNQS8mOrmmN4CeL5Rrg/edit#gid=0

const { GoogleSpreadsheet } = require("google-spreadsheet");

const SPREADSHEET_ID = "1v0Rq9FDatEwC8yKdqNWFtmIgLNQS8mOrmmN4CeL5Rrg";
const API_KEY = "AIzaSyChHsYHMDdiSNUAvHj2Fp_HAr8H7D81jPw";

export default async function getSheetDataByColumn() {
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  await doc.useApiKey(API_KEY);
  await doc.loadInfo();
  const sheet = doc.sheetsByTitle.Prompts;
  // eslint-disable-next-line no-unused-vars
  const rows = await sheet.getRows();

  return rows.map((row) =>
    // eslint-disable-next-line no-lone-blocks
    ({
      // eslint-disable-next-line no-unused-expressions
      id: row.id,
      // eslint-disable-next-line no-unused-expressions
      prompt: row.prompt,
      // eslint-disable-next-line no-unused-expressions
      input: row.input,
      // eslint-disable-next-line no-unused-expressions
      output: row.output,
    })
  );
}
