/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/login_background.png";
// eslint-disable-next-line import/named
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { login } from "../../../components/FirebaseLogin";
import config from "../../../components/firebaseconifg";

const app = initializeApp(config);
const auth = getAuth(app);

function SignInBasic() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  auth.onAuthStateChanged((user) => {
    if (user) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  });

  const [password, setPassword] = useState("");

  const [pwIncorrect, setPwIncorrect] = useState(false);

  const handlePasswordChange = (e) => setPassword(e);

  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => setEmail(e);

  const [rerender, setRerender] = useState(false);

  // eslint-disable-next-line consistent-return
  const handleLogIn = async () => {
    await login(email, password);
    setPwIncorrect(true);
    setRerender(!rerender);
  };

  const component = (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="primary"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="black" mt={1}>
                  Sign in
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="User"
                      fullWidth
                      onChange={(e) => handleEmailChange(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      onChange={(e) => handlePasswordChange(e.target.value)}
                    />
                  </MKBox>
                  {pwIncorrect && (
                    <MKBox mb={2}>
                      <MKTypography variant="body2" color="error">
                        Password incorrect
                      </MKTypography>
                    </MKBox>
                  )}
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="primary" fullWidth onClick={handleLogIn}>
                      <MKTypography variant="h5" color="black">
                        Log In
                      </MKTypography>
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );

  return isSignedIn ? <Navigate to="/gpt" /> : component;
}

export default SignInBasic;
