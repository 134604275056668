/* eslint-disable react/prop-types */
import React from "react";
import { Grid, Typography, Card, CardContent, CardMedia } from "@material-ui/core";

const OutputComponent = ({ elements }) => {
  if (!elements) {
    return <div />;
  }

  const shouldFillScreen = elements.length === 1 && elements[0].type === "Text";

  return (
    <div style={{ height: shouldFillScreen ? "100vh" : "auto" }}>
      <Grid container spacing={2}>
        {elements.map((element) => (
          <Grid
            item
            xs={12}
            sm={element.type === "Text" ? 12 : 4}
            md={element.type === "Text" ? 12 : 4}
            key={element.id}
          >
            {(() => {
              switch (JSON.parse(element.type || "{}").type) {
                case "Text":
                  return (
                    <Card>
                      <CardContent>
                        <Typography variant="body1">{element.value}</Typography>
                      </CardContent>
                    </Card>
                  );
                case "Image":
                  return (
                    <Card>
                      <CardMedia
                        component="img"
                        alt={element.label}
                        height="auto"
                        image={element.value}
                        title={element.label}
                      />
                    </Card>
                  );
                default:
                  return null;
              }
            })()}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default OutputComponent;
